import { useSelector } from 'react-redux';
import { experiments } from 'helpers';
import navOptions from './navOptions';
import { parentNav, rename, getRole } from './navRoleOptionsUtils';

export const getRoleOptions = (user) => {
	const {
		lifebooks,
		children,
		agency,
		administrators,
		managers,
		contributors,
		homes,
		resourceParents,
		socialWorkers,
		account,
		archive,
		vault,
		notifications,
		support,
		logout,
		casa,
		casaAgency,
		casaUsers,
		connections,
		manage,
		calendar,
		billing,
		activities,
	} = navOptions;

	const homesOptions = experiments.v2Api(true)
		? parentNav(homes, [rename(homes, 'Households'), resourceParents])
		: homes;

	const roleList = {
		'social-worker': [
			notifications,
			parentNav(children, [lifebooks, vault, calendar]),
			parentNav(homes, [
				rename(homes, 'Households'),
				resourceParents,
				contributors,
			]),
			support,
			parentNav(account, [rename(account, 'User Profile'), logout]),
		],
		privileged: [
			notifications,
			parentNav(children, [lifebooks, vault, archive, calendar]),
			parentNav(homes, [
				rename(homes, 'Households'),
				resourceParents,
				contributors,
			]),
			support,
			parentNav(account, [rename(account, 'User Profile'), logout]),
		],
		admin: [
			parentNav(children, [lifebooks, vault, archive, calendar]),
			homesOptions,
			parentNav(agency, [
				rename(agency, 'Branches'),
				socialWorkers,
				managers,
				administrators,
				contributors,
				experiments.activities(true) && activities,
			]),
			casaAgency,
			support,
			parentNav(account, [
				rename(account, 'User Profile'),
				experiments.billing(true) && billing,
				logout,
			]),
		],
		manager: [
			parentNav(children, [lifebooks, vault, archive, calendar]),
			homesOptions,
			parentNav(agency, [socialWorkers, managers, contributors]),
			casaAgency,
			support,
			parentNav(account, [rename(account, 'User Profile'), logout]),
		],
		child: [
			rename(lifebooks, 'LifeBook'),
			vault,
			calendar,
			account,
			support,
			logout,
		],
		house: [lifebooks, support, logout],
		'resource-parent': [lifebooks, calendar, support, account, logout],
		mentor: [lifebooks, calendar, children, account, support, logout],
		csw: [lifebooks, vault, calendar, support, account, logout],
		casauser: [
			parentNav(children, [lifebooks, calendar]),
			user.type === 'casauser' &&
				user.role === 'admin' &&
				parentNav(casa, [manage, casaUsers, connections]),
			contributors,
			support,
			parentNav(account, [rename(account, 'User Profile'), logout]),
		],
	};

	if (experiments.v2Api(true)) {
		roleList.contributor = [lifebooks, calendar, support, account, logout];
	}

	const nonMobileList = ['Archive', 'Vault'];

	return () => {
		const role = getRole(user);
		const { isSm } = useSelector(({ mediaQuery }) => mediaQuery);
		let returnList = roleList[role];

		const filterCallback = (val) => val && !nonMobileList.includes(val.name);

		if (isSm) {
			returnList =
				returnList
					?.map((val) => {
						if (!val) return null;
						if (!val.subList) return val;
						const filteredSubList = val.subList.filter(filterCallback);
						return { ...val, subList: filteredSubList };
					})
					.filter(filterCallback) || [];
		}

		returnList = returnList?.filter((val) => !!val);

		return returnList;
	};
};

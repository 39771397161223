import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useCreateDispatches, useCheckUserPermissions } from 'helpers/hooks';
import { includeType, excludeType, mixpanelTrackPost } from 'helpers';
import { handlePostValidation } from 'helpers/validation/postValidation';
import { getPostsRoot, getUserStateId } from 'redux/selectors';
import useCreateBody from './useCreateBody';
import { useSubmitPost } from 'services';
import * as services from 'services';

export default function useEditPostCallBacks(getPostState) {
	const { setSnackbar, setLoader, setChildPendCount } = useCreateDispatches();
	const { goBack, push } = useHistory();
	const { postId, childId } = useParams();
	const { includeUsers } = useCheckUserPermissions();
	const root = useSelector(getPostsRoot);
	const user = useSelector((state) => state.user.user);
	const child = useSelector((state) => state.lifebooks.child);
	const casaUserPermissions = useSelector(
		(state) => state.casa.casaUserPermissions
	);
	const casaPermissions = useSelector((state) => state.casa.casaPermissions);

	const childPending = useSelector(
		(state) => state.lifebooks.child.pendingPostCount
	);
	const userId = useSelector(getUserStateId);

	const onSuccessRedirect = (route) => {
		setLoader(false);
		push(route);
	};

	const onErrorCallback = (err) => {
		setLoader(false);
		setSnackbar({ variant: 'error', message: err.message });
	};

	const onCancel = (e) => {
		e.preventDefault();
		goBack();
	};

	const onDelete = () => {
		services
			.archivePost(postId)
			.then(() => {
				setSnackbar({
					variant: 'success',
					message: 'Post deleted successfully',
				});
				services.getCurrentChild(childId, { fetch: true });
				setLoader(false);
				goBack();
			})
			.catch((err) => {
				setSnackbar(
					{ variant: 'error', message: err.response?.data.message },
					{ log: true }
				);
			});
	};

	const getBody = useCreateBody();

	const createBody = async () => {
		const postState = getPostState();
		return await getBody(postState);
	};

	const getSuccessRedirect = (root, childId, nspId) => {
		let path = 'timeline';
		if (child.privatizePosts) {
			path = 'services';
		} else {
			path = includeUsers('agencyuser', 'activeChild', 'casauser')
				? nspId
					? 'services'
					: 'timeline'
				: 'pending';
		}
		return `/${root}/${path}/${childId}`;
	};

	const useOnSubmit = () => {
		const submitPost = useSubmitPost();
		return async (e, postType) => {
			e.preventDefault();
			const postState = getPostState();
			const { note } = postState;

			const validation = handlePostValidation(postState);

			if (!validation.isValid) {
				setSnackbar({ variant: 'error', message: validation.message });
				return;
			}

			const reqBody = await createBody(postState);
			setLoader(true);

			if (
				includeType(['agencyuser', 'csw', 'casauser']) &&
				postState.status !== 'approved' &&
				postState.id
			) {
				setChildPendCount({ childId, count: childPending - 1 });
			}

			submitPost(reqBody, postType)
				.then(async (res) => {
					const action = !postState?.id ? 'create' : 'edit';

					mixpanelTrackPost(action, postState, res.data);
					services.getCurrentChild(childId, { fetch: true });

					if (
						excludeType(['agencyuser', 'csw', 'child', 'casauser']) &&
						action === 'create'
					) {
						setChildPendCount({ childId, count: childPending + 1 });
					}

					if (
						user.type === 'casauser' &&
						user.role === 'admin' &&
						child.agencyId &&
						!casaPermissions?.mgmt_lifebook
					) {
						setChildPendCount({ childId, count: childPending + 1 });
					}

					if (
						user.type === 'casauser' &&
						user.role === 'mentor' &&
						child.agencyId &&
						!casaUserPermissions.mgmt_lifebook
					) {
						setChildPendCount({ childId, count: childPending + 1 });
					}

					if (note) {
						await services.addNote({
							note,
							postId: res?.data?.id,
							metadata: {
								userId,
							},
						});
					}

					onSuccessRedirect(getSuccessRedirect(root, childId, reqBody?.nspId));
				})
				.catch(onErrorCallback);
		};
	};

	return {
		onCancel,
		onDelete,
		useOnSubmit,
		onSuccessRedirect,
		onErrorCallback,
		createBody,
	};
}

import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import Modal from 'react-modal';
import AuthCodeInput from 'react-auth-code-input';
import { validatePhoneNumber } from 'helpers/validation/utils/validate';
import { useCreateDispatches } from 'helpers/hooks';
import { PrimaryButton, SecondaryButton, TextFieldRow } from 'components';
import { enableTwoFactor, getTwoFactorCode } from 'services/user-services';
import { getOwnUser } from 'services';
import styles from '../account.module.css';
import { modalStyle } from '../modalStyles';

const MultiFactorModal = () => {
	const modalOpen = useSelector((state) => state.user.authModal);
	const authCodeRef = useRef(null);
	const [phone, setPhone] = useState('');
	const [passcode, setPasscode] = useState('');
	const [codeSent, setCodeSent] = useState(false);

	const { set2faModal, setSnackbar, setLoader } = useCreateDispatches();

	useEffect(() => {
		setCodeSent(false);
		setPhone('');
		setPasscode('');
	}, []);

	const handleCancel = () => {
		set2faModal(false);
		setCodeSent(false);
		setPhone('');
	};

	const handleError = async (error) => {
		if (error?.response?.status === 403) {
			setSnackbar({
				variant: 'error',
				message: error.response?.data?.message,
			});
		} else {
			setSnackbar({
				variant: 'error',
				message: 'There was an error processing your request',
			});
		}
		await setLoader(false);
	};

	const handleRequestCode = async () => {
		const validPhone = validatePhoneNumber(phone);
		if (validPhone) {
			await setLoader(true);
			getTwoFactorCode(phone)
				.then(async () => {
					await setLoader(false);
					setCodeSent(true);
				})
				.catch(async (error) => {
					handleError(error);
				});
		} else {
			setLoader(false);
			setSnackbar({
				variant: 'error',
				message: 'Phone Number is not valid',
			});
		}
	};

	const handleValidateCode = async () => {
		if (passcode.length === 6) {
			await setLoader(true);
			enableTwoFactor(phone, passcode)
				.then(async (e) => {
					await getOwnUser('fetchUpdate');
					await setLoader(false);
					setSnackbar({
						variant: 'success',
						message: 'Two-Factor Authorization is now enabled',
					});
					setCodeSent(false);
					set2faModal(false);
				})
				.catch(async (error) => {
					handleError(error);
				})
				.finally((e) => {
					authCodeRef.current.clear();
				});
		} else {
			await setLoader(false);
			setSnackbar({
				variant: 'error',
				message: 'Code is not valid',
			});
		}
	};

	const handleSubmit = () => {
		codeSent ? handleValidateCode() : handleRequestCode();
	};

	return (
		<div style={{ zIndex: 5000 }}>
			<Modal
				ariaHideApp={false}
				style={modalStyle}
				isOpen={modalOpen}
				transparent={false}>
				<div className={styles.modalContainer}>
					<div className={styles.textContainer}>Two-Factor Authentication </div>
					{!codeSent ? (
						<div className={styles.inputContainer}>
							<TextFieldRow
								label={'Phone Number'}
								className={styles.reasonContainer}
								value={phone}
								onChange={(e) => setPhone(e.target.value)}
							/>
						</div>
					) : (
						<AuthCodeInput
							ref={authCodeRef}
							containerClassName={styles.authContainer}
							inputClassName={styles.authInput}
							onChange={(e) => setPasscode(e)}
						/>
					)}
					<div className={styles.lowerTextContainer}>
						{!codeSent ? (
							<p>
								Enter your phone number to receive a code to enable two-factor
								authentication.
							</p>
						) : (
							<p>
								A message with a verification code has been sent to your
								devices. Enter the code to continue.
							</p>
						)}
					</div>
					<div className={styles.buttonContainer}>
						<PrimaryButton onClick={() => handleSubmit()}>Submit</PrimaryButton>
						<SecondaryButton onClick={() => handleCancel()}>
							Close
						</SecondaryButton>
					</div>
				</div>
			</Modal>
		</div>
	);
};

export default MultiFactorModal;

import { useEffect, useState, useCallback } from 'react';
import { useParams, useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getLifebooksChild, getUserState, getPostsRoot } from 'redux/selectors';
import { getCurrentChild, getSnapshotChild } from 'services';
import { useCreateDispatches } from 'helpers/hooks';
import { useFormInput } from 'helpers';

const defaultPageTitle = 'Add Child';

const defaultStateValues = {
	firstname: '',
	middlename: '',
	lastname: '',
	placementDate: null,
	dob: null,
	gender: '',
	caseNumber: '',
	mobile: '',
	email: '',
	ethnicity: '',
};

export default function useManageChildBasicState() {
	const params = useParams();
	const { childId } = params;
	const root = useSelector(getPostsRoot);
	const user = useSelector(getUserState);
	const { push } = useHistory();
	const reduxChild = useSelector(getLifebooksChild);
	const { setChild } = useCreateDispatches();

	const location = useLocation();
	const isArchive = location.pathname?.includes('archive');

	const firstname = useFormInput('First Name', reduxChild.firstname || '');
	const middlename = useFormInput('Middle Name', reduxChild.middlename || '');
	const lastname = useFormInput('Last Name', reduxChild.lastname || '');
	const placementDate = useFormInput(
		'Placement Date',
		reduxChild.placementDate || null,
		{ type: 'date' }
	);
	const dob = useFormInput('Date of Birth', reduxChild.dob || null, {
		type: 'date',
	});
	const gender = useFormInput('Gender', reduxChild.gender || '');
	const ethnicity = useFormInput('Ethnicity', reduxChild.ethnicity || '');
	const caseNumber = useFormInput('Case Number', reduxChild.caseNumber || '');
	const mobile = useFormInput('Phone', reduxChild.mobile || '', {
		type: 'phone',
	});
	const email = useFormInput('Email', reduxChild.email || '');

	const state = {
		firstname,
		middlename,
		lastname,
		placementDate,
		dob,
		gender,
		caseNumber,
		mobile,
		email,
		ethnicity,
	};

	const setState = (changes) => {
		Object.keys(changes).forEach((label) => {
			state[label]?.onChange({ target: { value: changes[label] } });
		});
	};

	const setDefaults = () => {
		setState(defaultStateValues);
	};

	const getChildFunction = async (childId) => {
		if (isArchive) {
			return await getSnapshotChild(childId);
		} else {
			return await getCurrentChild(childId);
		}
	};

	const initialize = useCallback(
		async (childId) => {
			let childResult = await getChildFunction(childId).catch(() => {
				push('/lifebooks/');
			});

			if (!childResult || (childResult && childResult.error)) {
				push('/lifebooks/');
			} else {
				setDefaults();
				setPageTitle(`${childResult.firstname} ${childResult.lastname}`);
				setState(childResult);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[push, user.type]
	);

	useEffect(() => {
		if (!childId && user.type !== 'child') {
			setChild({});
			setPageTitle(defaultPageTitle);
			setDefaults();
		} else {
			root && initialize(childId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [childId, user.type, initialize, root]);

	useEffect(() => {
		if (reduxChild.ownershipCodeVerified) {
			setState(reduxChild);
		} else {
			setDefaults();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reduxChild.ownershipCodeVerified]);

	const [pageTitle, setPageTitle] = useState(defaultPageTitle);

	const values = Object.keys(state).reduce((acc, cur) => {
		acc[cur] = state[cur].value;
		return acc;
	}, {});

	return {
		pageTitle,
		state,
		setState,
		values,
		initialize,
	};
}

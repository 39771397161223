import { useState, useEffect, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { setUser, setMobileDashboard, setLoader } from 'redux/reducers';
import * as services from 'services';
import * as helpers from 'helpers';
import * as utils from './utils/settingsUtils';
import * as Comp from 'components';
import MultiFactor from './components/MultiFactor';
import ChildToggles from './components/ChildToggles';
import LogoutButton from './components/LogoutButton';
import NotificationToggles from './components/NotificationToggles';
import AccountImages from './components/AccountImages';
import styles from './account.module.css';
import useManageAccountImage from './utils/useManageAccountImage';
import MultiFactorModal from './components/MultiFactorModal';

function OwnerProfile(props) {
	const { user, setMobileDashboard, history } = props;

	const [privatize, setPrivatize] = useState(Boolean(user.privatizePosts));

	const infoState = utils.useInfoState();
	const notifications = utils.useNotificationToggles(user);
	const handleValidate = utils.useValidate();
	const imageState = useManageAccountImage();

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const setProfileFromProps = useCallback(async (user) => {
		//set user with the onChange function from useFormInput hook
		infoState.firstname.onChange({ target: { value: user.firstname || '' } });
		infoState.lastname.onChange({ target: { value: user.lastname || '' } });
		infoState.email.onChange({ target: { value: user.email || '' } });
		infoState.mobile.onChange({
			target: {
				value: user.mobile ? helpers.handlePhoneInput(user.mobile) : '',
			},
		});
		setPrivatize(user.privatizePosts);
	});

	useEffect(() => {
		setProfileFromProps(user);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const submitRequest = async () => {
		const { firstname, lastname, email, mobile, oldPassword, newPassword } =
			infoState || {};
		const { setSnackbar, setLoader } = props;

		const notificationBody = helpers.includeType(['agencyuser'])
			? utils.notificationReqBuilder(notifications)
			: {};

		const reqBody = {
			firstname: firstname.value,
			lastname: lastname.value,
			email: email.value,
			mobile: mobile.value === undefined ? '' : mobile.value,
			...notificationBody,
		};

		if (props.user?.role !== 'admin') {
			reqBody.role = undefined;
			reqBody.branchId = undefined;
		}

		if (newPassword.value) {
			reqBody.password = oldPassword.value;
			reqBody.newPassword = newPassword.value;
		}

		if (imageState.file?.file) {
			reqBody.photo = await services.getLocationAndUpload(
				imageState.file.file,
				user.id
			);
		} else if (!imageState.photo) {
			reqBody.photo = '';
		}

		const promiseArray = [];
		if (helpers.includeType(['child'])) {
			if (helpers.experiments.v2Api(true)) {
				if (user.privatizePosts !== privatize) {
					reqBody.privatizePosts = privatize;
				}
			}
		}

		promiseArray.push(services.updateUser(reqBody));

		Promise.all(promiseArray)
			.then(() => {
				services.getOwnUser('fetchUpdate');
				setSnackbar({
					variant: 'success',
					message: 'Profile Updated Successfully',
				});
				setLoader(false);
			})
			.catch((error) => {
				error.response?.data?.message &&
					setSnackbar({
						variant: 'error',
						message: error.response.data.message,
					});
				setLoader(false);
			});
	};

	const onSubmit = () => {
		if (handleValidate(infoState)) {
			props.setLoader(true);
			submitRequest();
		}
	};

	const onCancel = () => {
		history.goBack();
	};

	const onMobileNavBack = () => {
		setMobileDashboard(true);
	};

	let buttonArray = [
		<Comp.SecondaryButton key='account-cancel' onClick={onCancel}>
			Cancel
		</Comp.SecondaryButton>,
		<Comp.PrimaryButton key='account-save' onClick={onSubmit}>
			Save
		</Comp.PrimaryButton>,
		<LogoutButton key='account-logout' />,
	];

	return (
		<Comp.PageLayout
			title='Edit Profile'
			navBack={onMobileNavBack}
			buttonArray={buttonArray}>
			<MultiFactorModal />
			<div className={styles.settingsContent}>
				<AccountImages {...{ ...imageState }} />
				<Comp.ProfileBasics
					infoState={infoState}
					user={user}
					onSubmit={onSubmit}
				/>
				{helpers.includeActiveChild() && helpers.excludeArchivedChild() && (
					<ChildToggles {...{ privatize, setPrivatize }} />
				)}
				{helpers.includeType([
					'agencyuser',
					'casauser',
					'csw',
					'resource-parent',
				]) && <MultiFactor />}
				{helpers.includeType(['agencyuser']) && (
					<NotificationToggles notifications={notifications} />
				)}
			</div>
		</Comp.PageLayout>
	);
}

const mapStateToProps = ({ user }) => {
	return {
		user: user.user,
	};
};

const mapDispatchToProps = {
	setUser,
	setMobileDashboard,
	setLoader,
};

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(OwnerProfile)
);

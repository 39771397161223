import store from '../store';

const snackbarState = {
	open: false,
	variant: '',
	message: 'success',
};

const SET_SNACKBAR = 'SET_SNACKBAR';
const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';

export default function loaderReducer(state = snackbarState, action) {
	switch (action.type) {
		case SET_SNACKBAR: {
			if (action.payload.variant === 'error' && !action.payload.message) {
				action.payload.message =
					'Something went wrong. Please try again later.';
			}
			return {
				open: true,
				...action.payload,
			};
		}
		case CLOSE_SNACKBAR:
			return {
				open: false,
				variant: '',
				message: 'success',
			};
		default:
			return state;
	}
}

export function setSnackbar(snackBar, { loggedIn = true } = {}) {
	if (loggedIn) {
		const { id } = store.getState()?.user?.user || {};
		if (!id && !snackBar.twoFactor) {
			return {
				type: CLOSE_SNACKBAR,
			};
		}
	}
	return snackBar?.message
		? {
				type: SET_SNACKBAR,
				payload: snackBar,
		  }
		: closeSnackbar();
}

export function setSnackbarError({ response, message }, params) {
	const { data } = response || {};
	return setSnackbar(
		{ variant: 'error', message: data?.message || message },
		params
	);
}

export function closeSnackbar() {
	return {
		type: CLOSE_SNACKBAR,
	};
}

const dev = 'dev';
const sandbox = 'sandbox';
const production = 'production';
const environment = process.env.REACT_APP_ENVIRONMENT;

const setExperimentQuery = (experimentName) => {
	// creates an override value in local storage that can be checked for
	// the experiment
	if (window?.location?.search?.includes(`${experimentName}=true`)) {
		localStorage.setItem(experimentName, true);
	} else {
		if (!localStorage.getItem('token')) {
			localStorage.removeItem(experimentName);
		}
	}
};

setExperimentQuery('v2Api');

const envCheck = (allowList, storageKey) => {
	// if the setExperimentQuery is used for an experiment,
	// get the override from storage
	if (storageKey && localStorage.getItem(storageKey)) return (flag) => flag;
	return (flag) => (flag ? allowList.includes(environment) : false);
};

const experiments = {
	v2Api: envCheck([production, dev, sandbox], 'v2Api'),
	familyConnect: envCheck([dev, sandbox, production]),
	mixpanel: envCheck([sandbox, production]),
	notificationToggle: envCheck([dev, sandbox]),
	changeRequest: envCheck([dev, sandbox, production]),
	archive: envCheck([dev, sandbox, production]),
	archiveActivity: envCheck([production, dev, sandbox]),
	billing: envCheck([dev, sandbox]),
	activities: envCheck([dev, sandbox, production]),
	newInvite: envCheck([dev, sandbox]),
};

export default experiments;

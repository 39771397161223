import ActivityItem from '../components/CalendarActivityItem';

export function useCalendarActivityMapper(lastPostActivityId) {
	const getDescription = (val) => {
		if (val.activity === 'created') {
			return `New Calendar Event Added by ${val.name}`;
		}
		if (val.activity === 'edited') {
			return `Calendar Event Edited by ${val.name}`;
		}
		else {
			return `Calendar Event Deleted by ${val.name}`
		}
	};

	return (val, index, arr) => {
		const description = getDescription(val);

		return (
			<ActivityItem
				key={val.id}
				activity={{
					...val,
					description,
					index,
					only: arr.length === 1 && index === 0,
					last: !lastPostActivityId && index === arr.length - 1,
				}}
			/>
		);
	};
}
